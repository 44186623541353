<template>
  <v-menu
    v-if="items"
    bottom
    content-class="elevation-24"
    left
    min-width="200"
    offset-y
    :transition="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        dark
        text
        :ripple="false"
      >
        <span>Actions</span>
        <v-icon right>
          {{ mdiMenuDown }}
        </v-icon>
      </v-btn>
    </template>

    <v-list
      dense
    >
      <template
        v-for="(item, i) in items"
      >
        <v-list-item
          v-if="item"
          link
          :disabled="item.disabled"
          :key="i"
          :ripple="false"
          @click="item.action"
        >
          <v-list-item-icon
            dense
            class="ml-0 mr-4"
          >
            <v-icon
              :color="item.color"
              :disabled="item.disabled"
            >
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ item.label }}
          </v-list-item-title>
        </v-list-item>
        <v-divider v-else :key="i"/>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiMenuDown } from "@mdi/js"

export default {
  props: {
    items: {
      type: Array,
      default () { return [] }
    }
  },
  data: () => ({ mdiMenuDown })
}
</script>
