<template>
  <v-container fluid class="pb-16">
    <PageTitle />
    <div class="d-flex flex-grow-1">
      <div
        style="width:80px;min-width:80px;max-width:80px"
      >
        <YearList
          :selected="year"
          @select="selectYear"
        />
      </div>
      <div
        class="pl-3 flex flex-grow-1"
      >
        <div class="d-flex align-center mt-2 mb-5 justify-space-between">
          <RadioGroup
            field="type"
            :column="false"
            :items="typeOptions"
            :value="findingType"
            @change="selectType"
          />
          <div class="d-flex align-center">
            <v-chip
              color="success"
              class="ma-0 mr-2 px-3"
              small
            >
              <div class="text-body-1 font-weight-black">{{ total }}</div>
            </v-chip>
            Findings
          </div>
        </div>
        <DataTable
          :cols="cols"
          :data="items"
          @selectOne="view"
          @sort="sort"
          @filter="filter"
        />
      </div>
    </div>
    <FindingOverlay
      idkey="archive"
      :finding="selected"
      @close="selected = null"
    />
  </v-container>
</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'

import { fields } from '@/models/finding'

import FindingOverlay from '@/components/shared/finding-overlay'
import RadioGroup from '@/components/UI/forms/radio-group'
import YearList from '@/components/UI/year-list'

export default {
  components: {
    FindingOverlay,
    RadioGroup,
    YearList
  },
  data () {
    return {}
  },
  setup(props, context) {
    const { $store } = context.root
    const { Finding } = context.root.$FeathersVuex.api

    const acUserSelectedCS = computed (() => $store.state.acUserSelectedCS)

    const findingType = ref('All')
    const user = $store.state.auth.user
    const selected = ref(null)
    const year = ref(new Date().getFullYear())

    const typeOptions = [
      'All',
      'Non-Conformity',
      'Observation',
      'No Finding'
    ]

    const $select = [
      '_id',
      'acbClass',
      'acbACB',
      'acbAuditor',
      'acbDate',
      'acbFinding_Type',
      'acbFindingFile',
      'acbFlag',
      'acbLocation',
      'acbProposedCompDate',
      'acbCompDate',
      'acbCloseDate'
    ]

    const $filter = ref({})

    const $sort = ref({ acbDate: -1 })

    const $limit = 500

    const classClause = computed(() => {
      if (user.type === 'ac') {
        // need this to filter the local store
        // when an AC switches CS
        let cls = user.assignedTo[acUserSelectedCS.value].value
        if (cls === 'DNV') {
          cls = { $in: ['DNV', 'DNV-GL'] }
        }
        return { acbClass: cls }
      } else if (user.type === 'cs') {
        // need this to filter the local store
        // when any record is updated elsewhere
        let cls = user.cs.value
        if (cls === 'DNV') {
          cls = { $in: ['DNV', 'DNV-GL'] }
        }
        return { acbClass: cls }
      } else {
        return null
      }
    })

    const flagStateClause = computed(() => {
      if (user.type === 'fs') {
        // need this to filter the local store
        // when any record is updated elsewhere
        // console.warn(user.fs)
        let flg = user.fs.name
        return { acbFlag: flg }
      } else {
        return null
      }
    })


    const dateClause = computed(() => {
      const yyyy = user.type !== 'ob' ? year.value : Math.max(2019, year.value)
      return {
        acbDate: {
          $gte: new Date(`${yyyy}-01-01`),
          $lt: new Date(`${year.value + 1}-01-01`)
        }
      }
    })

    const typeClause = computed(() => {
      return findingType.value !== 'All'
        ? { acbFinding_Type: findingType.value }
        : null
    })

    const params = computed(() => {
      const obCutoff = new Date( Date.now() - (Number(3.154e+10) * 1.5) ) // 18 'months' in milliseconds
      // this query is used locally on the vuex store
      return {
        qid: 'archive',
        query: {
          $select,
          $limit,
          $or: [
            {
              $and: [
                { acbFinding_Type: 'Non-Conformity' },
                { acbCloseDate: { $ne: null } }
              ]
            },
            {
              $and: [
                { acbFinding_Type: 'Observation' },
                // closed or older than 18 months
                { $or: [
                  { acbCloseDate: { $ne: null } },
                  { acbDate: { $lt: obCutoff } }
                ]}
              ]
            },
            {
              acbFinding_Type: 'No Finding'
            }
          ],
          ...classClause.value,
          ...flagStateClause.value,
          ...dateClause.value,
          ...typeClause.value,
          ...$filter.value,
          $sort: $sort.value,
        }
      }
    })

    const { items, paginationData } = useFind({
      model: Finding,
      params: params,
    })

    const total = computed(() => {
      return ((paginationData.value.archive || {}).mostRecent || {}).total
    })

    const some = user.type !== 'ob'
                  ? [
                      'acbClass',
                      'acbACB',
                      'acbAuditor'
                    ]
                  : []

    const cols = [
        ...some,
        'acbDate',
        'acbFinding_Type',
        'acbFindingFile',
        'acbLocation',
        'acbProposedCompDate',
        'acbCompDate',
        'acbCloseDate'
      ]
      .map(c => fields.find(f => f.field === c))
      .map(f => ( { ...f, label: f.shortname } ))

    const filter = value => {
      $filter.value = { ...value }
    }

    const selectType = item => {
      findingType.value = item.value
    }

    const selectYear = value => {
      year.value = value
    }

    const sort = value => {
      $sort.value = { ...value }
    }

    const view = async item => {
      const result = await $store.dispatch('findings/get', item._id )
      selected.value = result
    }

    return {
      cols,
      dateClause,
      filter,
      findingType,
      items,
      paginationData,
      selected,
      selectType,
      selectYear,
      sort,
      total,
      typeOptions,
      view,
      year
    }
  }

}
</script>
