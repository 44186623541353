<template>
  <v-container fluid class="pb-16">
    <div class="d-flex flex-grow-1">
      <div
        style="width:80px;min-width:80px;max-width:80px"
      >
        <YearList
          :selected="year"
          @select="selectYear"
        />
      </div>
      <div
        class="pl-3 flex flex-grow-1"
      >
        <div class="d-flex align-center mt-2 mb-5 justify-space-between">
          <RadioGroup
            field="type"
            :column="false"
            :items="typeOptions"
            :value="findingType"
            @change="selectType"
          />
          <v-layout align-center class="my-n1">
            <div class="field-label text-caption mx-3">
              <span class="font-weight-bold primary--text">
                Finding Status
              </span>
            </div>
            <div
              style="max-width:280px;width:280px"
            >
              <Select
                required
                :items="statusOptions"
                :value="findingStatus"
                @change="selectStatus"
              />
            </div>
          </v-layout>
          <v-spacer />
          <div class="d-flex align-center">
            <v-chip
              color="success"
              class="ma-0 mr-2 px-3"
              small
            >
              <div class="text-body-1 font-weight-black">{{ total }}</div>
            </v-chip>
            Findings
          </div>
        </div>
        <DataTable
          :cols="cols"
          :data="items"
          @filter="filter"
          @selectOne="view"
          @sort="sort"
        />
      </div>
    </div>
    <FindingOverlay
      idkey="open-findings"
      :finding="selected"
      @close="selected = null"
    >
      <template v-slot:proposed="{ finding }">
        <slot name="proposed" :finding="finding"/>
      </template>
      <template v-slot:cas="{ finding }">
        <slot name="cas" :finding="finding"/>
      </template>
      <template v-slot:notes="{ finding }">
        <slot name="notes" :finding="finding" :close="() => selected = null"/>
      </template>
    </FindingOverlay>
  </v-container>
</template>

<script>

import { useFind, useGet } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'

import { fields } from '@/models/finding'

import FindingOverlay from '@/components/shared/finding-overlay'
import RadioGroup from '@/components/UI/forms/radio-group'
import Select from '@/components/UI/forms/select'
import YearList from '@/components/UI/year-list'

export default {
  components: {
    FindingOverlay,
    RadioGroup,
    Select,
    YearList
  },
  setup(props, context) {
    const { Finding } = context.root.$FeathersVuex.api
    const { $store } = context.root

    const acUserSelectedCS = computed (() => $store.state.acUserSelectedCS)
    const findingType = ref('Non-Conformity')
    const findingStatus = ref(0)
    const selected = ref(null)
    const year = ref(new Date().getFullYear())
    const user = $store.state.auth.user

    const statusOptions = [
      { text: 'All', value: 0 },
      { text: 'With Proposed Completion Date', value: 1 },
      { text: 'With Completion Date', value: 2 }
    ]

    const typeOptions = [
      'Non-Conformity',
      'Observation'
    ]

    // need to include the closed date
    // so that the record is reactive to
    // being closed by another user

    const $select = [
      '_id',
      'acbClass',
      'acbACB',
      'acbAuditor',
      'acbDate',
      'acbFindingFile',
      'acbFinding_Type',
      'acbFlag',
      'acbLocation',
      'acbProposedCompDate',
      'acbCompDate',
      'acbCloseDate'
    ]

    const $filter = ref({})

    const $sort = ref({ acbDate: -1 })

    const $limit = 5000

    const classClause = computed(() => {
      if (user.type === 'ac') {
        // need this to filter the local store
        // when an AC switches CS
        let cls = user.assignedTo[acUserSelectedCS.value].value
        if (cls === 'DNV') {
          cls = { $in: ['DNV', 'DNV-GL'] }
        }
        return { acbClass: cls }
      } else if (user.type === 'cs') {
        // need this to filter the local store
        // when any record is updated elsewhere
        let cls = user.cs.value
        if (cls === 'DNV') {
          cls = { $in: ['DNV', 'DNV-GL'] }
        }
        return { acbClass: cls }
      } else {
        return null
      }
    })

    const flagStateClause = computed(() => {
      if (user.type === 'fs') {
        // need this to filter the local store
        // when any record is updated elsewhere
        // console.warn(user.fs)
        let flg = user.fs.name
        return { acbFlag: flg }
      } else {
        return null
      }
    })

    const dateClause = computed(() => ({
      acbDate: {
        $gte: new Date(`${year.value}-01-01`),
        $lt: new Date(`${year.value + 1}-01-01`)
      }
    }))

    const statusClause = computed(() => {
      switch (findingStatus.value) {
        case 1:
          return { acbProposedCompDate: { $ne: null } }
        case 2:
          return { acbCompDate: { $ne: null } }
      }
    })

    const typeClause = computed(() => {
      return findingType.value !== 'All'
        ? { acbFinding_Type: findingType.value }
        : null
    })

    const params = computed(() => {
      const obCutoff = new Date( Date.now() - (Number(3.154e+10) * 1.5) ) // 18 'months' in milliseconds
      // this query is used locally on the vuex store
      return {
        qid: 'openFindings',
        query: {
          $select,
          // either
          acbCloseDate: null,
          $or: [
            {
              acbFinding_Type: 'Non-Conformity'
            },
            // or
            {
              acbCloseDate: null,
              acbFinding_Type: 'Observation',
              // and
              // younger than 18 months
              acbDate: { $gte: obCutoff }
            }
          ],
          ...classClause.value,
          ...flagStateClause.value,
          ...dateClause.value,
          ...statusClause.value,
          ...typeClause.value,
          ...$filter.value,
          $sort: $sort.value,
          $limit,
        }
      }
    })

    const { items, paginationData } = useFind({
      model: Finding,
      params: params,
    })

    const total = computed(() => {
      return ((paginationData.value.openFindings || {}).mostRecent || {}).total
    })

    const selectType = item => {
      findingType.value = item.value
    }

    const selectStatus = item => {
      findingStatus.value = item.value.value
    }

    const selectYear = value => {
      year.value = value
    }

    const some = user.type !== 'ob'
                  ? [
                      'acbClass',
                      'acbACB',
                      'acbAuditor'
                    ]
                  : []

    const cols = [
        ...some,
        'acbDate',
        'acbFindingFile',
        'acbLocation',
        'acbProposedCompDate',
        'acbCompDate'
      ]
      .map(c => fields.find(f => f.field === c))
      .map(f => ( { ...f, label: f.shortname } ))

    const filter = value => {
      $filter.value = { ...value }
    }

    const sort = value => {
      $sort.value = { ...value }
    }

    const view = async i => {
      const { item } = await useGet({
        model: Finding,
        id: i._id
      })
      selected.value = item.value
    }

    return {
      cols,
      filter,
      findingStatus,
      findingType,
      items,
      print,
      selected,
      selectStatus,
      selectType,
      selectYear,
      sort,
      statusOptions,
      total,
      typeOptions,
      view,
      year
    }
  }
}
</script>
