<template>
  <div>
    <v-sheet
      v-if="isOnline"
      color="light-green accent-2"
      class="px-2 py-1 rounded-pill primary--text font-weight-black"
      style="font-size: 0.81rem;"
    >ONLINE</v-sheet>
    <v-sheet
      v-else
      color="red"
      class="px-2 py-1 rounded-pill font-weight-black"
      style="font-size: 0.81rem;"
    >OFFLINE</v-sheet>
  </div>
</template>

<script>
export default {
  computed: {
    isOnline () { return this.$store.state.socket }
  }
}
</script>
