<template>
  <div
    class="d-flex align-center"
    v-if="title"
  >
    <v-icon
      class="mx-3"
    >
      {{ mdiArrowRight }}
    </v-icon>
    {{ title }}
  </div>
</template>

<script>
import { mdiArrowRight } from "@mdi/js"

export default {
  computed: {
    title () {
      return (this.$route.meta.page || {}).title
    },
    icon () {
      return (this.$route.meta.menu || {}).icon
    }
  },
  data: () => ({ mdiArrowRight })
}
</script>
