<template>
  <DocumentDrawer
    v-if="finding"
    :bgcolor="editing ? 'lavenderblush': 'white'"
    :color="editing ? 'error' : 'primary' "
    :open="Boolean(finding)"
    :stateless="false"
    :width="960"
    :title="title"
    @close="() => { editing = false; $emit('close') }"
  >
    <template v-slot:actionMenu>
      <ActionMenu
        :items="actionMenuItems"
      />
    </template>

    <v-container
      v-if="finding"
      class="px-6 mt-0"
      fluid
    >
      <v-row
        v-if="finding"
        dense
        no-gutters
      >
        <v-col class="">
          <Finding
            :idkey="idkey"
            :editing="editing"
            :finding="finding"
          >
            <template v-slot:proposed="{ finding }">
              <slot name="proposed" :finding="finding"/>
            </template>
            <template v-slot:cas="{ finding }">
              <slot name="cas" :finding="finding"/>
            </template>
            <template v-slot:notes="{ finding }">
              <slot name="notes" :finding="finding"/>
            </template>
          </Finding>
        </v-col>
      </v-row>
    </v-container>
  </DocumentDrawer>
</template>

<script>
import { computed, ref } from '@vue/composition-api'

import {
  mdiLinkVariant,
  mdiPrinter,
  mdiPencil,
  mdiPencilOff
} from "@mdi/js"
import ActionMenu from '@/components/UI/action-menu'
import DocumentDrawer from '@/components/UI/document-drawer'
import Finding from "@/components/shared/findings/single-view"
import { copyToClipboard } from '@/modules/copy-to-clipboard'

export default {
  props: {
    finding: {
      type: Object,
      default: () => { return null }
    },
    idkey: {
      type: String,
      default: ''
    }
  },
  components: {
    ActionMenu,
    DocumentDrawer,
    Finding,
  },
  setup(props, context) {

    const { $snotify, $store } = context.root
    const user = $store.state.auth.user
    const actionMenuItems = computed(() => {
      const items = [
        {
          label: 'Copy Direct Link',
          icon: mdiLinkVariant,
          action: copyLink
        },
        {
          label: 'Print',
          icon: mdiPrinter,
          action: print
        }
      ]
      if (user.type === 'oc') {
        if (editing.value) {
          items.push({
            label: 'Stop Editing',
            icon: mdiPencilOff,
            action: () => editing.value = false
          })
        } else {
          items.push({
            label: 'Edit',
            icon: mdiPencil,
            action: () => editing.value = true
          })
        }
      }
      return items
    })
    const editing = ref(false)
    const title = computed(() => {
      if (!props.finding) return ''
      const f = props.finding.acbFindingFile || '-'
      const title = f.replace('.xml', '')
      return `${editing.value ? 'Editing - ' : ''} ${title}`
    })
    const copyLink = () => {
      const url = `https://iacs-findingsdb.org.uk/finding/${props.finding.acbFindingFile.replace('.xml', '')}`
      copyToClipboard(url)
      $snotify.success('Direct link was copied to your clipboard', 'Success!')
    }
    const print = () => {
      const printWindow = window.frames[`printFrame-${props.idkey}`]
      const printFrame = document.getElementById(`printFrame-${props.idkey}`)
      const findingSrc = document.getElementById(`findingSrc-${props.idkey}`)
      const cssLink = document.createElement("link")
      printFrame.srcdoc = findingSrc.outerHTML
      cssLink.rel = "stylesheet"
      cssLink.type = "text/css"
      cssLink.onload = function(){
        printWindow.print()
        printWindow.document.open()
      }
      cssLink.href = "/css/print.css"
      setTimeout(() => { printWindow.document.head.appendChild(cssLink) }, 100)
    }
    return {
      actionMenuItems,
      copyLink,
      editing,
      print,
      title
    }
  }
}

</script>
