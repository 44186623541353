<template>
  <v-list
    nav
    class="ml-n3"
    style="position:sticky;top:60px"
  >
    <v-list-item-group
      v-model="year"
      mandatory
      no-action
    >
      <v-list-item
        v-for="y in years"
        :key="y"
        :ripple="false"
        :value="y"
        dense
        link
        color="primary"
        class="justify-center"
      >
        {{ y }}
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  props: {
    selected: {
      type: Number,
      default: new Date().getFullYear()
    }
  },
  computed: {
    years () {
      const years = []
      let year = new Date().getFullYear()
      while (year > 2010) {
        years.push(year--)
      }
      return years
    },
    year: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('select', value)
      }
    }

  }
}
</script>
